import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import {useStyles} from '../style';
import {RootStateOrAny, useSelector} from 'react-redux';
import {strings} from '@app/common/strings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import theme from '@app/themes';
import {Stack} from '@mui/system';
import OrderSupplyPod from './orderSupplyPod';

const OrderProductDetails = ({
  userLoginInfo,
  hide,
  approvalStatus,
  supplyPod,
  orderInnerFormData,
  productData,
}: any) => {
  const classes = useStyles();

  const productLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormProductLoading,
  );

  return (
    <Box sx={{position: 'relative', marginTop: '-8px'}}>
      {!productLoader ? (
        <>
          {!hide && (
            <Typography
              sx={{
                fontSize: '15.7px !important',
                marginBottom: '0px !important',
              }}
              className={`${classes.RfHeading}  `}
              variant="h6">
              {strings.zho.productDetails}
            </Typography>
          )}
          <Table
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0px 8px',
              width: !hide ? '100%' : '60%',
            }}
            aria-label="simple table">
            <TableHead>
              <TableRow sx={{transform: 'translatey(16px)'}}>
                <TableCell
                  sx={{background: 'white', border: '1px solid #E2E2E2'}}
                  className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} `}></TableCell>
                <TableCell
                  sx={{fontSize: '11.7px'}}
                  align="center"
                  colSpan={2}
                  className={` ${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} `}>
                  {strings.zho.totalTradeDiscount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '11.7px'}}
                  colSpan={2}
                  className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                  {strings.zho.hoAdmin.dashboard.modalPopup.supplyQty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '11.7px'}}
                  colSpan={2}
                  className={`${classes.toggleProDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight}`}>
                  {strings.zho.hoAdmin.dashboard.modalPopup.availedDiscount}
                </TableCell>
              </TableRow>

              <TableRow
                sx={{transform: 'translatey(8px)'}}
                className={`${classes.proDeatilsThead}`}>
                <TableCell
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '10.7px',
                  }}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableLeftBorder} ${classes.proDetailsHeading} ${classes.cellPadding}`}>
                  {strings.zho.selectedproducts}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom}  ${classes.cellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}></TableCell>

                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{fontSize: '10.7px'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder}  ${classes.cellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
              </TableRow>
            </TableHead>
            {!hide ? (
              <TableBody className={`${classes.productDetailsTableBody}`}>
                {productData?.products?.map((data: any, index: number) => {
                  const isLastRow = index === productData?.products.length - 1;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{padding: '14px !important'}}
                        className={`${classes.proDetailsCompDivider} ${
                          classes.orderProductTableData
                        }   ${classes.productTableLeftBorder} ${
                          classes.selectedProductsWidth
                        } ${classes.borderBottomLeft} ${classes.cellPadding} ${
                          index === 0 ? '' : classes.borderTopLeft
                        }`}>
                        {data ? data?.productName : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                        {data ? data?.totalTradeQuantity : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding}`}>
                        {data ? data?.totalTradePercentage : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                        {data ? data?.supplyQuantity : '---'}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                        {data ? data?.supplyAvailedQty : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${
                          classes.orderProductTableData
                        } ${classes.productTableRightBorder} ${
                          classes.borderBottomRight
                        } ${index === 0 ? '' : classes.borderTopRight}
                             ${classes.cellPadding}`}>
                        {data ? data?.supplyAvailedQty : '---'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody className={`${classes.productDetailsTableBody}`}>
                {orderInnerFormData?.map((data: any, index: number) => {
                  const isLastRow = index === orderInnerFormData.length - 1;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{padding: '14px !important'}}
                        className={`${classes.proDetailsCompDivider} ${
                          classes.orderProductTableData
                        }   ${classes.productTableLeftBorder} ${
                          classes.selectedProductsWidth
                        } ${classes.borderBottomLeft} ${classes.cellPadding} ${
                          index === 0 ? '' : classes.borderTopLeft
                        }`}>
                        {data ? data?.productName : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                        {data ? data?.totalTradeQuantity : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData}  ${classes.cellPadding}`}>
                        {data ? data?.totalTradePercentage : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={2}
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder} ${classes.cellPadding}`}>
                        {data ? data?.supplyQuantity : '---'}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${classes.orderProductTableData} ${classes.proDetailsLeftBorder}  ${classes.cellPadding}`}>
                        {data ? data?.supplyAvailedQty : '---'}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.proDetailsCompDivider} ${
                          classes.orderProductTableData
                        } ${classes.productTableRightBorder} ${
                          classes.borderBottomRight
                        } ${index === 0 ? '' : classes.borderTopRight}
                         ${classes.cellPadding}`}>
                        {data ? data?.supplyAvailedQty : '---'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {/* supply Pod */}
          {!hide || !supplyPod ? null : <OrderSupplyPod />}

          {supplyPod && <OrderSupplyPod />}

          {/* supply Pod */}

          {/* remarks */}

          {!hide ||
            (approvalStatus === 3 && (
              <>
                <Typography
                  sx={{
                    marginBottom: '16px !important',
                    fontSize: '15.7px !important',
                  }}
                  className={`${classes.RfHeading}  `}
                  variant="h6">
                  {strings.moduleSpecificUtilities.remarks}
                </Typography>
                <Box
                  sx={{
                    marginBottom: '16px',
                  }}>
                  <Box
                    sx={{
                      border: '1px solid #e2e2e2',
                      borderRadius: '10px',
                      width: '100%',

                      padding: '0.8rem',
                    }}>
                    <Box>
                      <Typography
                        display="inline-block"
                        sx={{
                          marginBottom: '0px !important',
                        }}
                        className={classes.defaultLightText}
                        variant="subtitle2">
                        {
                          strings.zho.hoAdmin.dashboard.modalPopup
                            .HoadminRemakrs
                        }
                      </Typography>
                      <Typography
                        display="inline-block"
                        sx={{
                          marginBottom: '0px !important',
                          marginLeft: '2rem',
                          textTransform: 'capitalize !important',
                        }}
                        className={`${classes.priDefaultFontSize} ${classes.noWrap} `}
                        variant="subtitle2">
                        Remarks
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ))}

          {/* 
      remarks */}
          {!hide && (
            <Stack direction="row" justifyContent="flex-end">
              <Button
                data-testid="reject-form"
                variant="outlined"
                size="large"
                className={classes.paperButton}>
                {strings.reject}
              </Button>
              <Button
                data-testid="approve-form"
                variant="contained"
                className={classes.applyButton}>
                {strings.approve}
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <Table sx={{minHeight: '188.877px'}}>
          <Box
            className={classes.loader}
            sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
            <CircularProgress />
          </Box>
        </Table>
      )}
    </Box>
  );
};

export default OrderProductDetails;
