import Box from '@mui/material/Box';
import {Button, CircularProgress, Grid, Stack, Typography} from '@mui/material';
import {useStyles} from '../styles';
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux';
import {
  fetchFormApprovalReviewCreator,
  fetchFormRejectReviewDropDownCreator,
} from '../../redux-api/slice';
import {useEffect, useState} from 'react';
import {strings} from '@app/common/strings';
import DateFormat from '@app/screens/zho/utils/dateFormat';

interface ApproveralList {
  role: string;
  status: string;
  date: string | null;
  statusCode: number;
  approverName: string | null;
}

interface ApproveralData {
  approvalList: ApproveralList[];
  rejectedRemarks: string | null;
}

function ApprovalStatus({
  userLoginInfo,
  requestFormId,
  approvalStatus,
  setApprovalStatus,
  hasDeactivatedProduct,
  handleToggleModal,
  handleRejectToggle,
  isEditProducts,
  isEditDetails,
}: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const approvalData: ApproveralData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormApprovalData,
  );

  const roleOrder: string[] = ['SLM', 'TLM', 'FC'];
  const [sortedApprovalData, setSortedApprovalData] = useState<
    ApproveralList[]
  >([]);

  useEffect(() => {
    if (approvalData.approvalList?.length > 0) {
      const sortedList = [...approvalData?.approvalList].sort((a, b) => {
        return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role);
      });

      setSortedApprovalData(sortedList);
      setApprovalStatus(sortedList[2]?.statusCode);
    }
  }, [approvalData, setApprovalStatus]);

  const approvalStatusLoader = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormApprovalLoading,
  );

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#f5b825';
    } else if (status === 1) {
      return '#5abea6';
    } else if (status === 6) {
      return '#f5b825';
    } else if (status === 3 || status === 4) {
      return '#db3d3d';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const handleApprovalReviewForm = () => {
    dispatch(
      fetchFormApprovalReviewCreator(
        userLoginInfo?.staffPositionId,
        userLoginInfo?.userId,
        [requestFormId],
        hasDeactivatedProduct ? 'pApproval' : 'approval',
      ),
    );
    handleToggleModal();
  };

  const handleRejectReviewForm = () => {
    dispatch(fetchFormRejectReviewDropDownCreator());
    handleRejectToggle();
    // handleToggleModal();
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}>
        <Typography
          fontSize="15.7px"
          color="#1c1939"
          sx={{margin: '0px !important', fontWeight: '600 !important'}}
          className={`${classes.RfHeading} ${classes.noWrap}  `}
          variant="h6">
          {strings.zho.fcAdmin.requestForm.approvalStatus}
        </Typography>
        {approvalStatus === 0 && (
          <Box>
            <Button
              data-testid="button-reject"
              variant="outlined"
              disabled={isEditProducts || isEditDetails}
              size="large"
              className={classes.paperButton}
              onClick={handleRejectReviewForm}>
              {strings.reject}
            </Button>
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              disabled={isEditProducts || isEditDetails}
              onClick={handleApprovalReviewForm}>
              {hasDeactivatedProduct ? 'Partial Approve' : 'Approve'}
            </Button>
          </Box>
        )}
      </Stack>
      {!approvalStatusLoader ? (
        <>
          <Grid marginTop={2} container>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                sx={{fontWeight: '600 !important'}}
                className={`${classes.RfHeading} ${classes.priDefaultFontSize} ${classes.noBorderBottom} ${classes.cellPadding}`}>
                {strings.zho.slm}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                sx={{fontWeight: '600 !important'}}
                className={`${classes.RfHeading} ${classes.priDefaultFontSize} ${classes.noBorderBottom} ${classes.cellPadding}`}>
                {strings.zho.tlm}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '600 !important',
                  paddingLeft: '8px !important',
                }}
                className={`${classes.RfHeading} ${classes.priDefaultFontSize} ${classes.noBorderBottom} ${classes.cellPadding}`}>
                {strings.zho.fc}
              </Typography>
            </Grid>
          </Grid>

          <Grid className={classes.proDetailContainerBorder} container>
            <Grid item xs={4}>
              <Box className={classes.cellPadding}>
                <Typography
                  sx={{fontWeight: '400 !important'}}
                  style={{
                    color: getStatusColor(sortedApprovalData[0]?.statusCode),
                  }}
                  variant="body2">
                  {sortedApprovalData[0]?.status}
                </Typography>
                <Typography
                  fontSize="12.7px"
                  style={{marginTop: '8px'}}
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[0]?.approverName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className={classes.cellPadding}>
                <Typography
                  sx={{fontWeight: '400 !important'}}
                  style={{
                    color: getStatusColor(sortedApprovalData[1]?.statusCode),
                  }}
                  variant="body2">
                  {sortedApprovalData[1]?.status}
                </Typography>
                <Typography
                  fontSize="12.7px"
                  style={{marginTop: '8px'}}
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[1]?.approverName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                sx={{paddingLeft: '8px !important'}}
                className={classes.cellPadding}>
                <Typography
                  sx={{
                    fontWeight: '400 !important',
                  }}
                  style={{
                    color: getStatusColor(sortedApprovalData[2]?.statusCode),
                  }}
                  variant="body2">
                  {sortedApprovalData[2]?.status}
                </Typography>
                <Typography
                  sx={{marginTop: '8px'}}
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[2]?.approverName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className={classes.cellPadding}>
                <Typography
                  fontWeight="600"
                  color={'#8d8c9c'}
                  sx={{
                    opacity: '0.7',
                    textTransform: 'uppercase',
                    fontSize: 10.7,
                  }}
                  variant="body2">
                  {strings.date}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[0]?.date !== null
                    ? DateFormat(sortedApprovalData[0]?.date)
                    : '---'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className={classes.cellPadding}>
                <Typography
                  fontWeight="600"
                  color={'#8d8c9c'}
                  sx={{
                    opacity: '0.7',
                    textTransform: 'uppercase',
                    fontSize: 10.7,
                  }}
                  variant="body2">
                  {strings.date}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[1]?.date !== null
                    ? DateFormat(sortedApprovalData[1]?.date)
                    : '---'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                sx={{paddingLeft: '8px !important'}}
                className={classes.cellPadding}>
                <Typography
                  fontWeight="600"
                  color={'#8d8c9c'}
                  sx={{
                    opacity: '0.7',
                    textTransform: 'uppercase',
                    fontSize: 10.7,
                  }}
                  variant="body2">
                  {strings.date}
                </Typography>
                <Typography
                  className={classes.priDefaultFontSize}
                  variant="body2">
                  {sortedApprovalData[2]?.date !== null
                    ? DateFormat(sortedApprovalData[2]?.date)
                    : '---'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          className={classes.loader}
          sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
export default ApprovalStatus;
