import {useState, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
  BottomNavigation,
  Box,
  Button,
  Chip,
  Collapse,
  Typography,
} from '@mui/material';
import {filterSelector, filterStateActions} from './redux';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {applyFilterCreator} from './redux/filterHandler';
import {trackEvent} from '@app/utils/analytics';
import {
  EVENT_NAME_APPLY_BUTTON,
  EVENT_NAME_CLEAR_ALL,
} from '@app/utils/analytics/constants';

import PlanAndMeetFilter from './plan-and-meet';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import NotificationModal from './NotificationModal';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {expenseDAMasterStateActions} from '@app/screens/master-data-management/pages/expense-da-master/redux';
import {expenseTAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-ta-master/redux/selectors';
import {expenseTAMasterStateActions} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import RenderFilters from './RenderFilters';
import {withPreventDoubleClick} from '../hoc';
import {inventoryPRSelector} from '@app/screens/inventory-management/pages/PendencyReport/redux/selectors';

const drawerWidth = 270;
const styles = {
  sticky: (_theme: any) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      padding: '10px',
      position: 'fixed',
      zIndex: 1,
      backgroundColor: '#fff',
      width: '14%',
      borderRadius: '20px',
    };
  },
  checkbox: (theme: any) => {
    return {
      color: ` ${theme.palette.primary.main} !important`,
      padding: `0px !important`,
    };
  },
  checkboxdisable: () => {
    return {
      color: 'darkgray',
      padding: '0px !important',
    };
  },
  marginLeft15: {
    marginLeft: 1,
  },
  backgroundWhite: (theme: any) => {
    return {
      backgroundColor: theme.palette.white.main,
    };
  },
  chipFilter: (theme: any) => {
    return {
      color: theme.typography.rightSideBar.color,
      fontWeight: 'bold',
      backgroundColor: theme.palette.white.main,
    };
  },
  chipClearAll: (theme: any) => {
    return {
      // eslint-disable-next-line dot-notation
      color: theme.typography.rightSideBar['color'],
      fontWeight: 'bold',
      backgroundColor: theme.palette.white.main,
    };
  },
  paper: (theme: any) => {
    return {
      width: drawerWidth - 55,
      backgroundColor: theme.palette.white.main,
      position: 'fixed',
      bottom: 20,
      right: 25,
      boxShadow: 'none',
      borderRadius: '4px',
      zIndex: 100,
    };
  },
  paperButton: (theme: any) => {
    return {
      border: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
      width: '150px',
      height: '40px',
      fontSize: 12,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    };
  },
};

export const RightSidebar = () => {
  const dispatch = useDispatch();
  const [filterComponents, setFilterComponent] = useState<any>(<></>);
  const filters = useSelector(filterSelector.getFilterState(), shallowEqual);
  const filterBtn = useSelector(filterSelector.getFilterBtn());
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
  );
  const isActiveStatus = useSelector(
    expenseDAMasterStateSelector.getIsActiveState(),
  );
  const resetNavbarVersionDAMaster = useSelector(
    expenseDAMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );

  const resetNavbarVersionTAMaster = useSelector(
    expenseTAMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );
  const request = useSelector(filterSelector.getFilterOptions());

  const DebouncedBtn = withPreventDoubleClick(Button, 400);

  /* P3 changes for Notification */

  const selectedState = useSelector(inventoryPRSelector.getSelectedState());
  const subDetails = useSelector(inventoryPRSelector.getSelectedSubordinate());
  const notificationModalComponents = [
    navbarComponentName.home,
    navbarComponentName.ChallanShortQtyApproval,
    navbarComponentName.approveReturnItems,
    navbarComponentName.cnfReturnItems,
    navbarComponentName.inventoryRules,
    navbarComponentName.inTransitChallan,
    navbarComponentName.inTransitAccess,
    navbarComponentName.expectedUtilizationDate,
    navbarComponentName.inventoryConfigurator,
    navbarComponentName.doctorApproval,
    navbarComponentName.doctorUniverse,
  ];

  useEffect(() => {
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setApplyDisableStatus(true));
    };
  }, [navbarComponentNameSelector]);

  const clearAllFilter = () => {
    trackEvent(EVENT_NAME_CLEAR_ALL, {});
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));

    filtersCopy &&
      Object.keys(filtersCopy).forEach(key => {
        if (filtersCopy && filtersCopy[key]) {
          filtersCopy[key].completeApplied = false;

          if (filtersCopy[key] && filtersCopy[key].options) {
            Object.keys(filtersCopy[key].options).forEach(optionKey => {
              filtersCopy[key].options[optionKey] = false;
              return null;
            });
          }
        }
      });
    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(
      expenseDAMasterStateActions.setResetNavbarVersion(
        resetNavbarVersionDAMaster + 1,
      ),
    );
    dispatch(
      expenseTAMasterStateActions.setResetNavbarVersion(
        resetNavbarVersionTAMaster + 1,
      ),
    );
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: isActiveStatus,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
        expenseTypeId: request.expenseTypeId,
      }),
    );
  };

  const alterOptionFilterAppliedState = (
    state: any,
    parentKey: any,
    childKey: any,
  ) => {
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));
    if (
      filtersCopy &&
      filtersCopy[parentKey] &&
      filtersCopy[parentKey].completeApplied
    ) {
      filtersCopy[parentKey].completeApplied = true;
    }
    if (filtersCopy[parentKey] && filtersCopy[parentKey].options) {
      typeof filtersCopy[parentKey].options[childKey] === 'boolean' &&
        (filtersCopy[parentKey].options[childKey] = state);
      const filterState = Object.values(filtersCopy[parentKey].options)?.filter(
        (state: any) => state,
      );
      if (filterState?.length) {
        filtersCopy[parentKey].completeApplied = true;
      } else {
        filtersCopy[parentKey].completeApplied = false;
      }
    }
    dispatch(
      filterStateActions.setFilterStateIndividual({
        options: filtersCopy[parentKey],
        key: parentKey,
      }),
    );
  };

  const alterAllAppliedState = (state: any, key: any) => {
    const filtersCopy = JSON.parse(JSON.stringify(filters || {}));
    if (filtersCopy && filtersCopy[key]) {
      filtersCopy[key].completeApplied = state;
      if (filtersCopy[key] && filtersCopy[key].options) {
        if (state) {
          Object.keys(filtersCopy[key].options).forEach(optionKey => {
            filtersCopy[key].options[optionKey] = true;
            return null;
          });
        } else {
          Object.keys(filtersCopy[key].options).forEach(optionKey => {
            filtersCopy[key].options[optionKey] = false;
            return null;
          });
        }
      }

      navbarComponentNameSelector !== 'PendencyReport'
        ? dispatch(filterStateActions.setFilterState(filtersCopy))
        : dispatch(
            filterStateActions.setFilterStateIndividual({
              options: filtersCopy[key],
              key: key,
            }),
          );
    }
  };

  const getColor = (filter: any) => {
    if (
      Object.keys(filter?.options)?.length === 0 ||
      filter?.options.length === 0
    ) {
      return 'disabled';
    } else {
      return 'primary';
    }
  };

  const SecondaryListItems = function ({
    filter,
    parentKeyName,
    indexNumber,
  }: {
    filter: any;
    parentKeyName: any;
    indexNumber: any;
  }) {
    return (
      <>
        {filter ? (
          <Box marginBottom={'20px'} marginLeft={'10px'} component="div">
            {!(
              navbarComponentNameSelector === 'PendencyReport' &&
              parentKeyName === 'Divisions'
            ) && (
              <ListItem
                data-testid={`set-filter-Collapsed-state-${indexNumber}`}
                disablePadding
                button
                key={parentKeyName}
                onClick={event => {
                  event.stopPropagation(); // Prevent event from bubbling

                  if (
                    Object.keys(filter?.options)?.length !== 0 ||
                    filter?.options.length !== 0
                  ) {
                    dispatch(
                      filterStateActions.setFilterCollapsedState({
                        key: parentKeyName,
                        value: !filter.collapsed,
                      }),
                    );
                  }
                }}>
                <ListItemIcon>
                  <Checkbox
                    sx={
                      Object.keys(filter?.options)?.length !== 0 ||
                      filter?.options.length !== 0
                        ? styles.checkbox
                        : styles.checkboxdisable
                    }
                    checked={filter?.completeApplied}
                    disabled={filter?.options.length === 0}
                    data-testid={`alter-all-applied-state-${indexNumber}`}
                    onClick={event => {
                      event.stopPropagation(); // Prevent event from bubbling

                      if (
                        Object.keys(filter?.options)?.length !== 0 ||
                        filter?.options.length !== 0
                      ) {
                        alterAllAppliedState(
                          !filter?.completeApplied,
                          parentKeyName,
                        );
                      }
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={[
                    Object.keys(filter?.options)?.length === 0 ||
                    filter?.options.length === 0
                      ? {
                          color: 'darkgray  !important',
                          fontWeight: 'normal',
                          fontStyle: 'normal',
                          textAlign: 'left',
                        }
                      : {
                          color: '#1c1939',
                          fontWeight: 'normal',
                          fontStyle: 'normal',
                          textAlign: 'left',
                          fontSize: 12,
                        },
                  ]}
                  disableTypography={
                    Object.keys(filter?.options)?.length === 0 ||
                    filter?.options.length === 0
                  }
                  primary={
                    <Typography fontSize={12}>{parentKeyName}</Typography>
                  }
                />

                <Box paddingRight={'8px'}>
                  {!filter.collapsed && <ExpandLess color={getColor(filter)} />}
                  {filter.collapsed && <ExpandMore color={getColor(filter)} />}
                </Box>
              </ListItem>
            )}

            <Collapse in={!filter.collapsed} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding={true}
                sx={styles.marginLeft15}>
                {Object.keys(filter?.options || {})?.map(
                  (childKey: any, index: number) => {
                    return (
                      <ListItem disablePadding button key={childKey}>
                        <ListItemIcon>
                          <Checkbox
                            data-testid={`alter-option-filter-applied-state-${index}`}
                            sx={styles.checkbox}
                            checked={filter?.options[childKey]}
                            onClick={() => {
                              alterOptionFilterAppliedState(
                                !filter?.options[childKey],
                                parentKeyName,
                                childKey,
                              );
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          sx={{overflowWrap: 'break-word !important'}}
                          primary={
                            <Typography fontSize={12}>{childKey}</Typography>
                          }
                        />
                      </ListItem>
                    );
                  },
                )}
              </List>
            </Collapse>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getFilterLayout = (filtersInput: any) => {
    const filterItems: any = [];

    Object.keys(filtersInput).forEach((key, index) => {
      filterItems.push(
        <SecondaryListItems
          filter={filtersInput[key]}
          parentKeyName={key}
          indexNumber={index}
        />,
      );
    });

    return <Box paddingBottom={'30px'}>{filterItems}</Box>;
  };
  useEffect(() => {
    filters && Object.keys(filters).length > 0
      ? setFilterComponent(getFilterLayout(filters))
      : setFilterComponent(<></>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  let isButtonEnabled = () => {
    if (navbarComponentNameSelector === 'PendencyReport') {
      if (selectedState.length >= 1 || subDetails.length >= 1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const filterComponent = (
    <>
      <Box
        margin={'20px'}
        borderRadius={'20px'}
        sx={styles.backgroundWhite}
        className={`hide-scrollbar`}
        height={'100vh'}
        overflow={'scroll'}>
        {filters && Object.keys(filters).length > 0 && (
          <>
            {navbarComponentNameSelector === 'PendencyReport' && (
              <Box marginLeft={2}>
                <Typography fontSize={12} color={'red'}>
                  Note: Select checkboxes and click 'Search' to filter results
                </Typography>
              </Box>
            )}
            <Box
              display={'flex'}
              justifyContent="space-between"
              marginBottom={'10px'}
              padding={'10px'}
              sx={styles.sticky}>
              <Chip label="Filter" size="small" sx={styles.chipFilter} />
              <Chip
                data-testid="clear-filter"
                label="Clear All"
                size="small"
                clickable
                onClick={clearAllFilter}
                sx={styles.chipClearAll}
                disabled={isButtonEnabled()}
              />
            </Box>
            <Box
              marginTop={'50px'}
              position={'fixed'}
              overflow={'auto'}
              width={220}
              height={'79vh'}
              zIndex={1}
              sx={{overflowX: 'visible'}}>
              {filterComponents || <></>}
            </Box>
          </>
        )}
      </Box>
      {filters && Object.keys(filters).length > 0 && filterBtn && (
        <Box>
          <BottomNavigation
            sx={{backgroundColor: 'transparent'}}
            showLabels
            value={0}>
            <DebouncedBtn
              data-testid="apply-filter"
              variant="contained"
              onClick={() => {
                trackEvent(EVENT_NAME_APPLY_BUTTON, {});
                dispatch(applyFilterCreator(null));
              }}
              sx={styles.paperButton}>
              Apply
            </DebouncedBtn>
          </BottomNavigation>
        </Box>
      )}
    </>
  );

  const renderFilters = () => {
    switch (navbarComponentNameSelector) {
      case navbarComponentName.ComponentManagement:
      case navbarComponentName.MasterDataManagement:
      case navbarComponentName.ConfigurationManagement:
      case navbarComponentName.approveExpenses:
      case navbarComponentName.statusManager:
      case navbarComponentName.Expense:
      case navbarComponentName.commonheader:
        return filterComponent;
      case navbarComponentName.planAndMeet:
      case navbarComponentName.dcrUnlockRequest:
        return <PlanAndMeetFilter />;
      case navbarComponentName.approvalWorkflow:
      case navbarComponentName.gspSchedule:
      case navbarComponentName.positionManagement:
      case navbarComponentName.activityMapping:
      case navbarComponentName.home:
        return <></>;

      default:
        break;
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: '#f5f8f9',
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          border: 0,
        },
      }}
      variant="permanent"
      anchor="right">
      {navbarComponentNameSelector === navbarComponentName.planAndMeet ||
      navbarComponentNameSelector === navbarComponentName.dcrUnlockRequest ||
      navbarComponentNameSelector === navbarComponentName.positionManagement ||
      navbarComponentNameSelector === navbarComponentName.approvalWorkflow ||
      navbarComponentNameSelector ===
        navbarComponentName.MasterDataManagement ||
      navbarComponentNameSelector === navbarComponentName.ComponentManagement ||
      navbarComponentNameSelector ===
        navbarComponentName.ConfigurationManagement ||
      navbarComponentNameSelector === navbarComponentName.approveExpenses ||
      navbarComponentNameSelector === navbarComponentName.statusManager ||
      navbarComponentNameSelector === navbarComponentName.Expense ||
      navbarComponentNameSelector === navbarComponentName.commonheader ||
      navbarComponentNameSelector === navbarComponentName.approvalWorkflow ||
      navbarComponentNameSelector === navbarComponentName.activityMapping ||
      navbarComponentNameSelector === navbarComponentName.gspSchedule ? (
        <>
          {renderFilters()}

          <NotificationModal />

          <RenderFilters
            filterComponent={filterComponent}
            navbarComponentNameSelector={navbarComponentNameSelector}
          />
        </>
      ) : (
        <>
          {/* P3 changes for Notification */}
          {notificationModalComponents.includes(
            navbarComponentNameSelector,
          ) && <NotificationModal />}
          {filterComponent}
        </>
      )}
    </Drawer>
  );
};
