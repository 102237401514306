import Box from '@mui/material/Box';
import {Button, CircularProgress, Grid, Stack, Typography} from '@mui/material';
import {useStyles} from '../style';
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {strings} from '@app/common/strings';
import DateFormat from '@app/screens/zho/utils/dateFormat';
import {fetchOrderProductApprovalCreator} from '../../redux-api/slice';

interface ApproveralList {
  role: string;
  status: string;
  date: string | null;
  statusCode: number;
  approverName: string | null;
}
interface ApproveralData {
  approvalList: ApproveralList[];
  rejectedRemarks: string | null;
  podRejectedRemarks: string | null;
}

const OrderApprovalStatus = ({
  userLoginInfo,
  handleRejectToggle,
  handleToggleModal,
  orderFormId,
  setApprovalStatus,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const approvalData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalData,
  );

  const [sortedApprovalData, setSortedApprovalData] = useState<
    ApproveralList[]
  >([]);

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#f5b825';
    } else if (status === 1) {
      return '#5abea6';
    } else if (status === 6) {
      return '#f5b825';
    } else if (status === 3 || status === 4) {
      return '#db3d3d';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const approvalStatusLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormApprovalLoading,
  );

  const length = sortedApprovalData.length;
  let columns = 5;
  let xs = 1;

  if (length === 3) {
    columns = 3;
    xs = 1;
  } else if (length === 4) {
    columns = 12;
    xs = 4;
  } else if (length === 5) {
    columns = 5;
    xs = 1;
  }

  useEffect(() => {
    if (approvalData.approvalList?.length > 0) {
      setSortedApprovalData(approvalData?.approvalList);
      setApprovalStatus(approvalData[approvalData.length - 1]?.statusCode);
    }
  }, [approvalData]);

  const handleApprovalReviewForm = () => {
    dispatch(
      fetchOrderProductApprovalCreator(
        userLoginInfo?.staffPositionId,
        userLoginInfo?.userId,
        [orderFormId],
      ),
    );
    handleToggleModal();
  };

  const handleRejectReviewForm = () => {
    // dispatch(fetchFormRejectReviewDropDownCreator());
    handleRejectToggle();
    // handleToggleModal();
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}>
        <Typography
          fontSize="15.7px"
          color="#1c1939"
          sx={{margin: '0px !important', fontWeight: '600 !important'}}
          className={`${classes.RfHeading} ${classes.noWrap}  `}
          variant="h6">
          {strings.zho.fcAdmin.requestForm.approvalStatus}
        </Typography>

        <Box>
          <Button
            data-testid="button-reject"
            variant="outlined"
            size="large"
            className={classes.paperButton}
            onClick={handleRejectReviewForm}>
            {strings.reject}
          </Button>
          <Button
            data-testid="button-approve"
            variant="contained"
            className={classes.applyButton}
            onClick={handleApprovalReviewForm}>
            {strings.approve}
          </Button>
        </Box>
      </Stack>
      {!approvalStatusLoader ? (
        <>
          <Grid marginTop={2} columns={columns} container>
            {sortedApprovalData.map((data, index) => {
              return (
                <Grid key={index} item xs={xs}>
                  <Typography
                    variant="subtitle1"
                    sx={{fontWeight: '600 !important'}}
                    className={`${classes.RfHeading} ${classes.priDefaultFontSize} ${classes.noBorderBottom} ${classes.cellPadding}`}>
                    {data?.role}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>

          <Grid
            className={classes.proDetailContainerBorder}
            container
            columns={columns}>
            {sortedApprovalData.map((data, index) => {
              return (
                <Grid item xs={xs}>
                  <Box className={classes.cellPadding}>
                    <Typography
                      sx={{fontWeight: '400 !important'}}
                      style={{
                        color: getStatusColor(
                          sortedApprovalData[0]?.statusCode,
                        ),
                      }}
                      variant="body2">
                      {data?.status}
                    </Typography>
                    <Typography
                      fontSize="12.7px"
                      style={{marginTop: '8px'}}
                      className={classes.priDefaultFontSize}
                      variant="body2">
                      {data?.approverName}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            {sortedApprovalData.map((data, index) => {
              return (
                <Grid key={index} item xs={xs}>
                  <Box className={classes.cellPadding}>
                    <Typography
                      fontWeight="600"
                      color={'#8d8c9c'}
                      sx={{
                        opacity: '0.7',
                        textTransform: 'uppercase',
                        fontSize: 10.7,
                      }}
                      variant="body2">
                      {strings.date}
                    </Typography>
                    <Typography
                      className={classes.priDefaultFontSize}
                      variant="body2">
                      {data?.date !== null ? DateFormat(data?.date) : '---'}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <Box
          className={classes.loader}
          sx={[{display: 'flex', justifyContent: 'center', mt: 3}]}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default OrderApprovalStatus;
